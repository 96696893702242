@font-face {
  font-family: 'Poppins';
  src: url('./Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: url('./Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
